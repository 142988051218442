import React, { useState, useEffect, useRef } from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input';
import Swal from 'sweetalert2';
import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import { addTabuladorOperadores, updateTabuladorOperadores, getTabuladorOperadoresByRuta } from '../../../controllers/tabuladoroperadores'
import { addTarifario, updateTarifario } from '../../../controllers/tarifario'
import { getUserByUid } from '../../../controllers/usuarios';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import ModalCreateCliente from '../../clientes/ModalCreateEdit';
import ModalCreateUbicacion from '../../ubicaciones/ModalCreateEdit';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getClientes } from '../../../controllers/clientes';
import { getUbicaciones, getDistancia, registraNuevaDistancia, actualizaDistnacia } from '../../../controllers/viajes';
import { getTarifarioByViajeData } from '../../../controllers/tarifario';


const ModalCreateEdit = (props) => {

    //particularidades LOZJRkQRLvbN2mWSjX4MFNr1qvj2
    const requiereHazmatTarifa = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1'];
    const requiereSubliente = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1', '31RxHqtBOmbgOWWeMCKnb1DuEHc2'];
    const requiereSubliente2 = ['Is55uxcCDdMdI53LaFtWvyFXGH03'];
    const requiereSubTarifa = ['xxxHptPbvVxYYUjUzp7Nt3TtmyAt0x1'];
    const requiereComisiones = ['HptPbvVxYYUjUzp7Nt3TtmyAt0x1', 'RZHc17ViPqQTIJ7VShGfbBRwXBw1','P1MFFIe7zEQmdzWWRvBeHgYYQkJ3','JZXpy0kTUVfv2gP8iEMCjLexhrA2'];
    const requiereTarifaPorTonelada = ['LOZJRkQRLvbN2mWSjX4MFNr1qvj2'];
    //constantes

    //estados de control de modal y registro
    const [uid, setUid] = useState(null);
    const [uidAltaEdit, setUidAltaEdit] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [adminCurrentUser, setAdminCurrentUser] = useState(null);
    const [fecha, setFecha] = useState('');
    const [firstDay, setFirstDay] = useState('');
    const [lastDay, setLastDay] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [recordedit, setRecordEdit] = useState(null);
    const [isHazmat, setIsHazmat] = useState(false);
    const [isPorTonelada, setIsPorTonelada] = useState(false);


    const [isOpenCreateCliente, setIsOpenCreateCliente] = useState(false);
    const [isOpenCreateUbicacion, setIsOpenCreateUbicacion] = useState(false);
    const [localidades, setLocalidades] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [cliente, setCliente] = useState("");
    const [subcliente, setSubCliente] = useState("");


    const [subClienteId, setSubClienteId] = useState('');
    const [subClienteNombre, setSubClienteNombre] = useState('');
    //estados propios del formulario
    const [aliasCliente, setAliasCliente] = useState("");
    const [clienteId, setClienteId] = useState('');
    const [clienteNombre, setClienteNombre] = useState('');
    const [origen, setOrigen] = useState("");
    const [destino, setDestino] = useState("");
    const [origenId, setOrigenId] = useState("");
    const [destinoId, setDestinoId] = useState("");
    const [origenMunicipioSat, setOrigenMunicipioSat] = useState("");
    const [origenMunicipio, setOrigenMunicipio] = useState("");
    const [origenColonia, setOrigenColonia] = useState("");
    const [origenColoniaSat, setOrigenColoniaSat] = useState("");
    const [origenCiudadSat, setOrigenCiudadSat] = useState("");
    const [origenCiudad, setOrigenCiudad] = useState("");
    const [origenEstado, setOrigenEstado] = useState("");
    const [origenEstadoShort, setOrigenEstadoShort] = useState("");
    const [destinoMunicipioSat, setDestinoMunicipioSat] = useState("");
    const [destinoMunicipio, setDestinoMunicipio] = useState("");
    const [destinoColonia, setDestinoColonia] = useState("");
    const [destinoColoniaSat, setDestinoColoniaSat] = useState("");
    const [destinoCiudadSat, setDestinoCiudadSat] = useState("");
    const [destinoCiudad, setDestinoCiudad] = useState("");
    const [destinoEstado, setDestinoEstado] = useState("");
    const [destinoEstadoShort, setDestinoEstadoShort] = useState("");
    const [ruta, setRuta] = useState("");
    const [tarifa, setTarifa] = useState('');
    const [tarifaFloat, setTarifaFloat] = useState(0.0);
    const [subTarifa, setSubTarifa] = useState('');
    const [subTarifaFloat, setSubTarifaFloat] = useState(0.0);
    const [comisionOperadorFloat, setComisionOperadorFloat] = useState(0.0);
    const [comisionOperador, setComisionOperador] = useState("");
    const [currencyMx, setCurrencyMx] = useState(true);
    const [especiales, setEspeciales] = useState([]);
    const [usuarioobj, setUsuarioObj] = useState(null);
    const [segmentoNegocio, setSegmentoNegocio] = useState("");
    const [isEspecial, setIsEspecial] = useState(false);
    const [tarifaXTon, setTarifaXTon] = useState('');
    const [tarifaXTonFloat, setTarifaXTonFloat] = useState(0.0);

    const [distBase, setDistBase] = useState(0);

    useEffect(() => {
        setIsOpen(props.isOpen);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {

        }
    }, []);


    const initValues = () => {

        getUserByUid(uid).then(snapshot => {
            let obuser = null;
            snapshot.forEach(doc => {
                let o = doc.data();
                obuser = { ...doc.data(), id: doc.id, segmento_negocio: ["", ...doc.data().segmento_negocio] }
                if (!obuser.segmento_negocio) {
                    obuser.segmento_negocio = [""];
                }
                o.id = doc.id;
            });
            setUsuarioObj(obuser);

        }).catch(err => {
            console.log(err);
        });

        if (uid) {
            getClientes(uid).then(snapshot => {
                let aux = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    aux.push(o);
                });
                let toset = aux.map((item, index) => {
                    let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "user" + index };
                    return ob;
                });
                setClientes(toset);


            }).catch(err => { });

            getUbicaciones(uid).then(snapshot => {
                let aux = [];
                snapshot.forEach(doc => {
                    let o = doc.data();
                    o.id = doc.id;
                    aux.push(o);
                });
                let toset = aux.map((item, index) => {
                    let num = item.numero || "";
                    let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
                    idubica = idubica.trim();
                    let ob = { ...item, name: idubica, name2: item.nombre.trim(), value: idubica };
                    return ob;
                });
                setLocalidades(toset);

            }).catch(err => { });

        }

        if (isEdit && recordedit != null) {
            setOrigen(recordedit.origen || "");
            setDestino(recordedit.destino || "");
            setDestinoId(recordedit.destinoId || "");
            setOrigenId(recordedit.origenId || "");

            setRuta(recordedit.ruta || "");
            setSegmentoNegocio(recordedit.segmentoNegocio || "");
            let tarif = "";
            let subtarif = "";
            if (recordedit.tarifa) {
                tarif = recordedit.tarifa.split(" (E)").join("");
            }
            if (recordedit.subTarifa) {
                subtarif = recordedit.subTarifa.split(" (E)").join("");
            }
            setTarifa(tarif || "");
            setTarifaFloat(recordedit.tarifaFloat || "");
            setSubTarifa(subtarif || "");

            if (uid && requiereHazmatTarifa.find(rr => rr === uid) && recordedit.isHazmat === true || recordedit.isHazmat === false) {
                setIsHazmat(recordedit.isHazmat);
            } else {
                setIsHazmat(false);
            }

            if (uid && requiereTarifaPorTonelada.find(rr => rr === uid) && recordedit.isPorTonelada === true || recordedit.isPorTonelada === false) {
                setIsPorTonelada(recordedit.isPorTonelada);
                setTarifaXTon(recordedit.tarifaXTon);
                setTarifaXTonFloat(recordedit.tarifaXTonFloat);
            } else {
                setIsPorTonelada(false);
            }

            setComisionOperador(recordedit.comisionOperador || "");
            setComisionOperadorFloat(recordedit.comisionOperadorFloat || 0.0);

            setCurrencyMx(recordedit.currencyMx);
            setClienteNombre(recordedit.cliente || "");
            setCliente(recordedit.cliente || "");

            setSubCliente(recordedit.subcliente || "");
            setSubClienteId(recordedit.subClienteId || "");
            //setClienteNombre(recordedit.cliente || "");
            setClienteId(recordedit.clienteId || "");
            setEspeciales(recordedit.especiales || []);
            setIsEspecial(recordedit.isEspecial || false);
        } else {
            setIsEspecial(false);
            setOrigen("");
            setDestino("");
            setRuta("");
            setTarifa("");
            setTarifaFloat("");

            setComisionOperador("");
            setComisionOperadorFloat(0);

            setSubTarifa("");
            setSubTarifaFloat(0);

            setSegmentoNegocio("");
            setCurrencyMx(true);
            setEspeciales([]);
        }
    }


    useEffect(() => {
        setUid(props.uid);
        setUidAltaEdit(props.uidAltaEdit);
        setIsAdmin(props.isAdmin);
        setAdminCurrentUser(props.adminCurrentUser);
        initValues();
        setIsLoading(false);
        setIsOpen(props.isOpen);
        setIsEdit(props.isEdit);
        setRecordEdit(props.currenteditrecrod);

        try {
            setFirstDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth(), 1));
            setLastDay(new Date(props.currentDate.getFullYear(), props.currentDate.getMonth() + 1, 0));
        } catch (e) {
        }
    }, [props]);

    useEffect(() => {
        if (recordedit) {
            initValues();
        }

    }, [recordedit]);

    const checkIfTarifa = () => {
        if (!isEdit && clienteId && origenId && destinoId && segmentoNegocio) {
            console.log("checking if tarifa");
            getTarifarioByViajeData(uid, clienteId, segmentoNegocio, origenId, destinoId).then(snapshot => {
                if (snapshot.docs.length > 0) {
                    setIsEspecial(true);
                } else {
                    setIsEspecial(false);
                }
            }).catch(err => { console.log(err) })
        }

    }
    useEffect(() => {
        checkIfTarifa();
        getDistancia(uid, origen, destino).then(resp => {
            if (resp.size > 0) {
                if (resp.docs[0].data().distancia)
                    setDistBase(resp.docs[0].data().distancia);
            }
        }).catch(err => {
            console.log(err);
        });
    }, [clienteId, origenId, destinoId, segmentoNegocio]);

    useEffect(() => {
        if (!isOpen) {
            props.toggle();
        } else {
            if (isEdit && recordedit) {
                // setTipo(recordedit.tipo);

            } else {
                // setTipo('Costo Fijo');

                let datetoset = new Date();
                if (lastDay.getTime() >= datetoset.getTime()) {
                    setFecha(new Date());
                } else {
                    setFecha(props.currentDate);
                }
            }
        }
    }, [isOpen]);





    const handleSumbit = async (e) => {
        e.preventDefault();
        //setIsOpen(false);
        if (origen === "" || destino === "" || segmentoNegocio === "") {
            Swal.fire("Invalido", "Completa los campos requeridos", 'warning');
            return;
        }

        await verificaDistancias();
        let obj = {
            origen,
            destino,
            origenId,
            destinoId,
            clienteId,
            cliente,
            ruta: ruta.toUpperCase(),
            tarifa,
            tarifaFloat,
            subTarifa,
            subTarifaFloat,
            currencyMx,
            especiales,
            isEspecial,
            segmentoNegocio,
            uid,
        }

        if (uid && requiereSubliente.find(f => f === uid)) {
            obj.subcliente = subcliente;
            obj.subClienteId = subClienteId;
        }

        if (uid && requiereComisiones.find(f => f === uid)) {
            obj.comisionOperador = comisionOperador;
            obj.comisionOperadorFloat = comisionOperadorFloat;
        }
        if (uid && requiereTarifaPorTonelada.find(f => f === uid)) {
            obj.tarifaXTon = tarifaXTon;
            obj.tarifaXTonFloat = tarifaXTonFloat;
            obj.isPorTonelada = isPorTonelada;
        }

        if (uid && requiereSubliente2.find(f => f === uid)) {
            obj.subcliente = subcliente;
            obj.subClienteId = subClienteId;
        }

        if (uid && requiereHazmatTarifa.find(f => f === uid)) {
            obj.isHazmat = isHazmat;
        }

        if (isAdmin && adminCurrentUser) {
            obj.uid = adminCurrentUser;
        }

        //validaciones de formulario
        // console.log(obj);
        if (isEdit && recordedit.id) {
            // alert(recordedit.id);
            obj.useredit = uidAltaEdit || uid;
            setIsLoading(true);
            updateTarifario(recordedit.id, obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify({ ...obj, id: recordedit.id });
                Swal.fire('Éxito', 'El tabulador se ha actualizado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                console.log(err);
                Swal.fire("Error", "Error de al actualizar el tabulador, comprueba tu conexión ", 'error');
            })
        } else if (!isEdit) {
            obj.useralta = uidAltaEdit || uid;
            setIsLoading(true);
            addTarifario(obj).then(resp => {
                setIsLoading(false);
                setIsOpen(false);
                props.notify();
                Swal.fire('Éxito', 'El tabulador se ha registrado con éxito.', 'success');
            }).catch(err => {
                setIsLoading(false);
                Swal.fire("Error", "Error de al registrar el tabulador, comprueba tu conexión ", 'error');
            })
        } else {
            alert("no options");
        }
    }
    const AntSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 16,
            padding: 0,
            display: 'flex',
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            '&$checked': {
                transform: 'translateX(12px)',
                color: theme.palette.grey[500],
                '& + $track': {
                    opacity: 1,
                    backgroundColor: theme.palette.common.white,
                    borderColor: theme.palette.grey[500],
                },
            },
        },
        thumb: {
            width: 12,
            height: 12,
            boxShadow: 'none',
        },
        track: {
            border: `1px solid ${theme.palette.grey[500]}`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: theme.palette.common.white,
        },
        checked: {},
    }))(Switch);

    const handleChangeTarifa = (event, maskedvalue, floatvalue) => {
        setTarifa(maskedvalue);
        setTarifaFloat(floatvalue);
    }
    const handleChangeSubTarifa = (event, maskedvalue, floatvalue) => {
        setSubTarifa(maskedvalue);
        setSubTarifaFloat(floatvalue);
    }
    const handleChangeComisionOperador = (event, maskedvalue, floatvalue) => {
        setComisionOperador(maskedvalue);
        setComisionOperadorFloat(floatvalue);
    }
    const clienteAdded = (item) => {
        if (item && item.nombre) {
            let ob = { ...item, name: item.nombre + " - " + item.estado_short, value: item.id, id: "useradded" + clientes.length + 1 };
            let clientesaux = [...clientes];
            clientesaux.push(ob);
            setClientes(clientesaux);
        }
    }
    const verificaDistancias = async () => {
        await getDistancia(uid, origen, destino).then(async (resp) => {
            if (resp.docs.length > 0) {
                const distviajeEncontrado = resp.docs[0].data();
                if (distviajeEncontrado.distancia != distBase) {
                    await actualizaDistnacia(resp.docs[0].id, distBase).then(resp => {
                        console.log("distancia actualizada");
                    }).catch(err => { });
                }
            } else {
                await registraNuevaDistancia(uid, origen, destino, distBase).then(resp => { console.log("nueva distancia agregada") }).catch(err => { console.log(err) });
            }
        }).catch(err => {
            console.log(err);
        });
    }
    /*useEffect(() => {
        if (cliente && cliente != "" && clientes.length > 0) {
            let objf = clientes.find(element => element.name.trim() === cliente.trim());
            if (objf) {
                if (objf.value != clienteId) {
                    // console.log("cliente info", objf);
                    setClienteId(objf.value);
                }
            } else {
                console.log("no se encontro cliente ID")
            }
        }
    }, [cliente]);*/


    useEffect(() => {
        console.log("cambio subcliente", subcliente);

        if (uid && requiereSubliente.find(f => f === uid)) {
            if (subcliente && subcliente != "" && clientes.length > 0) {
                let objf = clientes.find(element => element.name.trim() === subcliente.trim());
                if (objf) {
                    if (objf.value != subClienteId) {
                        // console.log("cliente info", objf);
                        setSubClienteId(objf.value);
                    }
                } else {
                    console.log("no se encontro cliente ID")
                }
            } else if (subClienteId != '') {
                setSubClienteId('');
            }
        } else if (uid && requiereSubliente2.find(f => f === uid)) {
            if (subcliente && subcliente != "" && localidades.length > 0) {
                let objf = localidades.find(element => element.name2.trim() === subcliente.trim());
                if (objf) {
                    if (objf.value != subClienteId) {
                        // console.log("cliente info", objf);
                        setSubClienteId(objf.id || objf.value);
                    }
                } else {
                    console.log("no se encontro cliente ID")
                }
            } else if (subClienteId != '') {
                setSubClienteId('');
            }
        }


    }, [subcliente]);

    useEffect(() => {
        if (!isEdit) {
            if (origen && destino) {
                let origenOBJ = localidades.find(element => element.name === origen);
                let destinoOBJ = localidades.find(element => element.name === destino);
                if (origenOBJ && destinoOBJ) {
                    setRuta(origenOBJ.localidad + " - " + destinoOBJ.localidad);
                }
            }
            if (origen) {
                let origenOBJ = localidades.find(element => element.name == origen);
                if (origenOBJ) {
                    console.log("setting de efect en alta");
                    setOrigenId(origenOBJ.id);
                    setOrigenCiudad(origenOBJ.localidad);
                    setOrigenCiudadSat(origenOBJ.localidadSat || "");
                    setOrigenEstado(origenOBJ.estado);
                    setOrigenEstadoShort(origenOBJ.estado_short);
                    setOrigenMunicipioSat(origenOBJ.municipioSat || "");
                    setOrigenMunicipio(origenOBJ.municipio || "");
                    setOrigenColonia(origenOBJ.colonia || "");
                    setOrigenColoniaSat(origenOBJ.coloniaSat || "");

                }
            }
            if (destino) {
                let destinoOBJ = localidades.find(element => element.name == "" + destino);
                if (destinoOBJ) {
                    console.log("se encontro destino", destino);
                    setDestinoId(destinoOBJ.id);
                    setDestinoCiudad(destinoOBJ.localidad);
                    setDestinoEstado(destinoOBJ.estado);
                    setDestinoEstadoShort(destinoOBJ.estado_short);
                    setDestinoMunicipioSat(destinoOBJ.municipioSat || "");
                    setDestinoCiudadSat(destinoOBJ.localidadSat || "");
                    setDestinoMunicipio(destinoOBJ.municipio || "");
                    setDestinoColonia(destinoOBJ.colonia || "");
                    setDestinoColoniaSat(destinoOBJ.coloniaSat || "");
                }
            }
        }

    }, [origen, destino]);

    const ubicacionAdded = (obj) => {
        if (obj && obj.nombre) {

            let item = { ...obj };
            let num = item.numero || "";
            let idubica = item.nombre.trim() + " - " + item.calle.trim() + " " + num.trim();
            let ob = { ...item, name: idubica, value: idubica };
            try {
                localidades.push(ob);
            } catch (e) {

            }

        }
    }
    return <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="lg">
        <LoadingOverlay
            active={isLoading}
            spinner
            text='Registrando datos, espere ...'
        >
            <ModalHeader toggle={() => setIsOpen(false)}>
                {!isEdit ? "Añadir Tarifa por Ruta de Cliente" : `Editar Tarifa `}
            </ModalHeader>
            <form onSubmit={handleSumbit}>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Ruta</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Cliente<label className="requiredlabel">*</label><IconButton onClick={() => setIsOpenCreateCliente(true)} variant="contained" size="small" color="primary" aria-label="add">
                                        <AddIcon />
                                    </IconButton></p>
                                    <div class="fieldInput">
                                        {
                                            !isEdit ?
                                                <Autocomplete
                                                    id="combo-box-demo0c"
                                                    options={clientes}
                                                    getOptionLabel={(option) => option.alias || option.name}
                                                    getOptionSelected={(option, value) => option.name === value.name}
                                                    className={"selectbusqueda"}
                                                    style={{ padding: 0 }}
                                                    onChange={(e, val) => {
                                                        console.log("seting cliente id", val);
                                                        // setCliente(val.name);
                                                        setClienteId(val.value);

                                                        if (val.alias && val.alias !== "") {
                                                            setAliasCliente(val.alias);
                                                        } else {
                                                            setAliasCliente("");
                                                        }

                                                        setCliente(val ? val.name : "");

                                                    }}
                                                    renderInput={(params) => <TextField className="reducedselect" onChange={e => setCliente(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                                /> : <input type="text" value={clienteNombre} onChange={e => setClienteNombre(e.target.value)} variant="outlined" disabled />}
                                    </div>
                                </div>

                                {
                                    uid && requiereSubliente.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Subcliente<label className="requiredlabel">*</label><IconButton onClick={() => setIsOpenCreateCliente(true)} variant="contained" size="small" color="primary" aria-label="add">
                                            <AddIcon />
                                        </IconButton></p>
                                        <div class="fieldInput">
                                            <Autocomplete
                                                id="combo-box-demo0c"
                                                options={clientes}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                defaultValue={clientes.find(element => element.name.trim() === subcliente.trim())}
                                                className={"selectbusqueda"}
                                                style={{ padding: 0 }}
                                                onChange={(e, val) => { setSubCliente(val ? val.name : '') }}
                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => setSubCliente(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            />
                                        </div>
                                    </div>
                                }
                                {
                                    uid && requiereSubliente2.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Subcliente</p>
                                        <div class="fieldInput">
                                            <Autocomplete
                                                id="combo-box-demo0c"
                                                options={localidades}
                                                getOptionLabel={(option) => option.name2}
                                                getOptionSelected={(option, value) => option.name2 === value.name2}
                                                defaultValue={localidades.find(element => element.name2.trim() === subcliente.trim())}
                                                className={"selectbusqueda"}
                                                style={{ padding: 0 }}
                                                onChange={(e, val) => { setSubCliente(val ? val.name2 : '') }}
                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => setSubCliente(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            />
                                        </div>
                                    </div>
                                }

                                <div class="dataRow">
                                    <p className="fixedP">Origen<label className="requiredlabel">*</label><IconButton onClick={() => setIsOpenCreateUbicacion(true)} variant="contained" size="small" color="primary" aria-label="add">
                                        <AddIcon />
                                    </IconButton></p>
                                    <div class="fieldInput">
                                        {
                                            !isEdit ? <Autocomplete
                                                id="combo-box-demo0"
                                                options={localidades}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) => option.name === value.name}
                                                className={"selectbusqueda"}
                                                style={{ padding: 0 }}
                                                onChange={(e, val) => setOrigen(val.name)}
                                                renderInput={(params) => <TextField className="reducedselect" onChange={e => setOrigen(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                            /> : <input type="text" value={origen} onChange={e => setOrigen(e.target.value)} variant="outlined" disabled />}


                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Destino<label className="requiredlabel">*</label><IconButton onClick={() => setIsOpenCreateUbicacion(true)} variant="contained" size="small" color="primary" aria-label="add">
                                        <AddIcon />
                                    </IconButton></p>
                                    <div class="fieldInput"> {
                                        !isEdit ? <Autocomplete
                                            id="combo-box-demo0"
                                            options={localidades}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.name === value.name}
                                            className={"selectbusqueda"}
                                            style={{ padding: 0 }}
                                            onChange={(e, val) => setDestino(val.name)}
                                            renderInput={(params) => <TextField className="reducedselect" onChange={e => setDestino(e.target.value)} style={{ fontSize: '12px' }} {...params} variant="outlined" />}
                                        /> : <input type="text" value={destino} onChange={e => setDestino(e.target.value)} variant="outlined" disabled />}



                                    </div>
                                </div>

                                <div class="dataRow">
                                    <p className="fixedP">Ruteo</p>
                                    <div class="fieldInput">
                                        <input disabled type="text" name='cliente' value={ruta} onChange={e => setRuta(e.target.value)} required />
                                    </div>
                                </div>
                                <div class="dataRow">
                                    <p className="fixedP">Distancia Kms.</p>
                                    <div class="fieldInput">
                                        <input style={{ width: '90%' }} type="number" name='kms' value={distBase} onChange={e => setDistBase(e.target.value)} required />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dataTable twoCol">

                            <div class="dataTitle"><p className="fixedP">Tarifa</p></div>
                            <div class="dataBox">
                                <div class="dataRow">
                                    <p className="fixedP">Moneda</p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setCurrencyMx(e.target.value == "1")} value={currencyMx ? "1" : "2"} required >
                                            <option key={'simxn'} value={"1"} >MXN</option>
                                            <option key={'nodll'} value={"2"}>DLL</option>
                                        </select>
                                    </div>
                                </div>
                                {
                                    uid && requiereTarifaPorTonelada.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Tipo Tarifa</p>
                                        <div class="fieldInput">
                                            <select className='form-control' value={isPorTonelada === true ? 'Por Tonelada' : 'Fijo'} onChange={e => {
                                                e.target.value === "Por Tonelada" ? setIsPorTonelada(true) : setIsPorTonelada(false)
                                            }}>
                                                <option value="Fijo">Fijo</option>
                                                <option value="Por Tonelada">Por Tonelada</option>
                                            </select>
                                        </div>
                                    </div>
                                }
                                <div class="dataRow">
                                    <p className="fixedP">{isPorTonelada ? 'Monto (xTon)':'Monto'}<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <CurrencyInput style={{ backgroundColor: "#dbffcf", width: "90%" }} value={tarifa} onChangeEvent={handleChangeTarifa} prefix="$" required />

                                    </div>
                                </div>
                                {
                                    uid && requiereSubTarifa.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Sub-Monto<label className="requiredlabel">*</label></p>
                                        <div class="fieldInput">
                                            <CurrencyInput style={{ backgroundColor: "#dbffcf", width: "90%" }} value={subTarifa} onChangeEvent={handleChangeSubTarifa} prefix="$" required />

                                        </div>
                                    </div>
                                }
                                {
                                    uid && requiereComisiones.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Comision Operador</p>
                                        <div class="fieldInput">
                                            <CurrencyInput style={{ width: "90%" }} value={comisionOperador} onChangeEvent={handleChangeComisionOperador} prefix="$" />

                                        </div>
                                    </div>
                                }


                                {
                                    uid && requiereHazmatTarifa.find(f => f === uid) &&
                                    <div class="dataRow">
                                        <p className="fixedP">Hazmat<label className="requiredlabel">*</label></p>
                                        <div class="fieldInput">
                                            <select className='form-control' value={isHazmat === true ? 'Si' : 'No'} onChange={e => {
                                                e.target.value === "Si" ? setIsHazmat(true) : setIsHazmat(false)
                                            }}>
                                                <option value="No">No</option>
                                                <option value="Si">Si</option>
                                            </select>
                                        </div>
                                    </div>
                                }

                                <div class="dataRow">
                                    <p className="fixedP">Segmento de Negocio<label className="requiredlabel">*</label></p>
                                    <div class="fieldInput">
                                        <select name='segmento' onChange={(e) => setSegmentoNegocio(e.target.value)} value={segmentoNegocio} required >
                                            {
                                                usuarioobj && usuarioobj.segmento_negocio.map((item, index) => {

                                                    return segmentoNegocio == item.value ?
                                                        <option key={index} value={item.value} selected>{item.value}</option> :
                                                        <option key={index} value={item.value}>{item.value}</option>

                                                })
                                            }
                                        </select>

                                    </div>
                                </div>

                                <div className="col-3">
                                    <FormGroup>
                                        <FormControlLabel disabled control={<Checkbox checked={isEspecial} />} label="Especial" />
                                    </FormGroup>
                                </div>

                            </div>
                        </div>

                    </div>


                </ModalBody>
                <ModalFooter>
                    <div style={{ marginRight: 'auto' }}><label className="requiredlabel">*</label><label>Campos requeridos</label></div>
                    <button type="button" className="boton redBtn erase" style={{ backgroundColor: "#fcf0f0" }} onClick={() => setIsOpen(false)} >Salir</button>
                    <button type="submit" className="boton secBtn"> Aceptar</button>
                </ModalFooter>
            </form>
        </LoadingOverlay>

        <ModalCreateCliente
            findRecord={e => console.log(e)}
            currenteditrecrod={null}
            currentDate={new Date()}
            isEdit={false}
            isOpen={isOpenCreateCliente}
            uid={uid}
            adminCurrentUser={null}
            isAdmin={false}
            notify={(obj) => clienteAdded(obj)}
            setLoading={value => setIsLoading(value)}
            toggle={() => setIsOpenCreateCliente(false)} />
        <ModalCreateUbicacion
            findRecord={e => console.log(e)}
            currenteditrecrod={null}
            currentDate={new Date()}
            isEdit={false}
            isOpen={isOpenCreateUbicacion}
            uid={uid}
            adminCurrentUser={null}
            isAdmin={false}
            notify={(obj) => ubicacionAdded(obj)}
            setLoading={value => setIsLoading(value)}
            toggle={() => setIsOpenCreateUbicacion(false)} />
    </Modal >

}
export default ModalCreateEdit;